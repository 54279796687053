<template>
    <!-- TODO: ecommerce layout for header wrapper sidebar ETC -->
    <ecommerce-hero
            title="The Most Advanced Devices"
            :background="heroBackground">

    </ecommerce-hero>


    <div class="uk-container-large uk-margin-auto uk-padding-small">
        <ul class="uk-breadcrumb">
            <li><a href="#shop">Shop</a></li>
            <li class="uk-disabled"><a>Devices</a></li>
        </ul>
    </div>

    <div class="uk-container-large uk-margin-auto uk-margin-large-bottom uk-padding-small">
        <div class="ecommerce category">

            <h1 aria-live="assertive">Mobile Devices</h1>
            <div class="uk-flex" >

                <div class="filter uk-width-1-3 uk-width-1-6@m uk-card uk-card-default uk-card-body no-radius uk-padding-small uk-box-shadow uk-margin-right" >
                    <div class="sort-container uk-margin-small-bottom">
                        <form-input type="select"
                                    :list="sortOptions"
                                    v-model="sortBy"
                                    icon="list"></form-input>
                    </div>
                    <hr>
                    <h3 class="uk-margin-remove-top"><span uk-icon="settings" class="uk-margin-small-right"></span>Filter</h3>

                    <div class="sort-container uk-margin-bottom">
                        <h4 class="uk-margin-remove-bottom">Brand</h4>
                        <form-input type="checkbox"
                                    :wrapperMargins="false"
                                    label="Apple"></form-input>
                        <form-input type="checkbox"
                                    :wrapperMargins="false"
                                    label="Samsung"></form-input>
                        <form-input type="checkbox"
                                    :wrapperMargins="false"
                                    label="Other"></form-input>
                    </div>

                    <div class="sort-container uk-margin-bottom">
                        <h4 class="uk-margin-remove-bottom">Memory</h4>
                        <form-input type="checkbox"
                                    :wrapperMargins="false"
                                    label="16 GB"></form-input>
                        <form-input type="checkbox"
                                    :wrapperMargins="false"
                                    label="128 GB"></form-input>
                        <form-input type="checkbox"
                                    :wrapperMargins="false"
                                    label="256 GB"></form-input>
                    </div>
                    <div class="sort-container uk-margin-bottom">
                        <h4 class="uk-margin-remove-bottom">Price Range</h4>
                        <form-input type="range"
                                    :wrapperMargins="false"
                                    min="0"
                                    step="100"
                                    max="2500"
                                    value="2500"
                                    v-model="maxPriceFilter"
                                    :autoInputStyling="false"
                                    label="Maximum Price"></form-input>
                        <div class="uk-text-right"><span>Up To {{maxPriceFilter}} F</span></div>

                    </div>
                </div>

                <div class=" uk-width-2-3 uk-width-5-6@m   uk-box-shadow">
                    <div class="uk-child-width-1-1 uk-child-width-1-2@m uk-child-width-1-3@l uk-grid-match" uk-grid>
                        <div>
                            <div class="uk-card product-card uk-card-default "
                                 @click="$router.push({name: 'ecommerce-product', params: {productAlias:'phone'}})">
                                <div class="uk-card-media-top">
                                    <img src="images/products/product_small_sample_fill.jpg" alt="">
                                </div>
                                <div class="uk-card-body uk-padding-remove-vertical ">
                                    <div class="product-info-wrapper  uk-margin-medium-top uk-margin-small-bottom">
                                        <h3 class="uk-card-title uk-margin-small-bottom">Iphone 12 X</h3>
                                        <div class="product-meta">
                                            <p>Apple flagship device, with 55MPX camera</p>
                                        </div>
                                        <div class="product-prices uk-flex uk-flex-bottom">
                                            <div class="current-price uk-margin-small-right uk-text-primary uk-text-bold uk-text-large">2 233 F</div>
                                            <div class="old-price ">2 500 F</div>
                                        </div>
                                        <div class="product-promotions uk-padding-small uk-padding-remove-horizontal">
                                            <div class="loyalty-discount uk-badge uk-padding-small">
                                                10% discount for 5,000 Loyalty!
                                            </div>
                                        </div>
                                        <div class="product-cta uk-card-footer uk-padding-remove-horizontal">
                                            <form-button :fullWidth="true" icon="cart">ADD TO CART</form-button>
                                        </div>
                                    </div>


                                </div>
                            </div>
                        </div>

                        <div>
                            <div class="uk-card product-card uk-card-default "
                                 @click="$router.push({name: 'ecommerce-product', params: {productAlias:'phone'}})">
                                <div class="uk-card-media-top uk-overflow-hidden">
                                    <img class="uk-animation-kenburns" src="images/products/product_small_sample_fill.jpg" alt="">
                                </div>
                                <div class="uk-card-body uk-padding-remove-vertical ">
                                    <div class="product-info-wrapper  uk-margin-medium-top uk-margin-small-bottom">
                                        <div class="uk-card-badge uk-background-primary uk-badge uk-padding-small">
                                            <span uk-icon="check"></span><span>PROMOTION BADGE!</span>
                                        </div>
                                        <h3 class="uk-card-title uk-margin-small-bottom">Iphone 11 X</h3>
                                        <div class="product-meta">
                                            <p>Apple flagship device, with 15MPX camera</p>
                                        </div>
                                        <div class="product-prices uk-flex uk-flex-bottom">
                                            <div class="current-price uk-margin-small-right uk-text-primary uk-text-bold uk-text-large">2 500 F</div>
                                            <div class="old-price ">3 250 F</div>
                                        </div>
                                        <div class="product-promotions uk-padding-small uk-padding-remove-horizontal">
                                            <div class="loyalty-discount uk-badge uk-padding-small">
                                                Some Other Promotion!
                                            </div>
                                        </div>
                                        <div class="product-cta uk-card-footer uk-padding-remove-horizontal">
                                            <form-button :fullWidth="true" icon="cart">ADD TO CART</form-button>
                                        </div>
                                    </div>


                                </div>
                            </div>
                        </div>

                        <div>
                            <div class="uk-card product-card uk-card-default "
                                 @click="$router.push({name: 'ecommerce-product', params: {productAlias:'phone'}})">
                                <div class="uk-card-media-top">
                                    <img src="images/products/product_small_sample_fill.jpg" alt="">
                                </div>
                                <div class="uk-card-body uk-padding-remove-vertical ">
                                    <div class="product-info-wrapper  uk-margin-medium-top uk-margin-small-bottom">
                                        <h3 class="uk-card-title uk-margin-small-bottom">Iphone 11 X</h3>
                                        <div class="product-meta">
                                            <p>Apple flagship device, with 15MPX camera</p>
                                        </div>
                                        <div class="product-prices uk-flex uk-flex-bottom">
                                            <div class="current-price uk-margin-small-right uk-text-primary uk-text-bold uk-text-large">1 233 F</div>
                                            <div class="old-price ">2 500 F</div>
                                        </div>
                                        <div class="product-promotions uk-padding-small uk-padding-remove-horizontal">

                                        </div>
                                        <div class="product-cta uk-card-footer uk-padding-remove-horizontal">
                                            <form-button :fullWidth="true" icon="cart">ADD TO CART</form-button>
                                        </div>
                                    </div>


                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    </div>

</template>

<script>

    import asyncOperations from "@/client/extensions/composition/asyncOperations";
    import ecommerceHero from "@/client/components/ecommerce/Hero.vue";

    export default {
        props: {
            categoryAlias: {
                type: [String, Boolean],
                default: 'devices'
            },
            heroBackground: {
                type: [String],
                default: 'ecommerce/headers/sample_header_2.jpg'
            }
        },
        components: {
            ecommerceHero
        },
        data: function () {
            return {
                sortBy: '0',
                maxPriceFilter: 2500,
                sortOptions : [
                    {
                        value: '0',
                        label: 'Sort...'
                    }
                ]
            };
        },
        computed: {


        },
        methods: {

        },
        mounted () {

        },
    }
</script>

<style scoped lang="scss">

    .hero {
        position: relative;
        height: 150px;

        .background {
            height: 100%;
            width: 100%;
            left: 50%;
            top: 50%;

            z-index: 0;
            overflow: hidden;


            .background-inner {

                height: calc(100% + 40px);
                width: calc(100% + 40px);
                left: calc(50% - 20px);
                top: calc(50% - 20px);

                background-size: cover;
                filter: blur(2px);
                -webkit-filter: blur(2px);
            }
        }

        .background-overlay {
            position: absolute;
            left: 0;
            top: 0;
            height: 100%;
            width: 100%;
            background: rgba(0,0,0,0.35);
            z-index: 0;
        }

        .content {
            position: relative;
            z-index: 1;
            height: 100%;
        }
    }

    .product-card {
        cursor: pointer;
        display: flex;
        flex-direction: column;
        flex-grow: 5;

        .uk-card-body {
            display: flex;
            flex-direction: column;
            flex-grow: 5;

        }

        .product-info-wrapper {
            display: flex;
            flex-direction: column;
            flex-grow: 5;

            .product-cta{
                margin-top: auto;
            }
        }

        .uk-card-media-top {
            width: 100%;
            height: 250px;
            display: flex;
            align-items: center;
            justify-content: center;

            img {
                max-height: 100%;
                max-width: 100%;
                width: 100%;
                height: auto;
                margin: auto;

            }
        }

        .old-price {
            position: relative;
            top: -4px;
            text-decoration: line-through;

        }
    }

</style>
